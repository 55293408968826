import React from 'react';
import styled, { css } from 'styled-components';
import Head from 'components/Head';
import { PageProps } from 'gatsby';
import Section from 'components/generic/Section';
import { Stack } from 'components/generic/Stack';
import { h2Heading, h4Heading, universalMargin } from 'styles';
import * as legacyColors from 'styles/baseColors';
import { variables as pwpVariables } from 'white-label/pwp/variables';
import { variables as wwtVariables } from 'white-label/wwt/variables';
import { WhitelabelBranding } from 'white-label/shared/types';

let completeColorList: Record<string, string> = {
  ...extractBrandedColors(wwtVariables)
    .map(colorEntry => flipKeyAndValues(colorEntry))
    .reduce((acc, clr) => ({ ...acc, ...clr })),
  ...extractBrandedColors(pwpVariables)
    .map(colorEntry => flipKeyAndValues(colorEntry))
    .reduce((acc, clr) => ({ ...acc, ...clr })),
  ...flipKeyAndValues(legacyColors),
};
let allColors = Object.entries(completeColorList);

export default function ColorsPreview({ location }: PageProps<null, null>) {
  return (
    <>
      <Head
        location={location}
        title="Active colors in PWP"
        description="These are the active pages we use in Cisco Project Workplace"
      />
      <Spacing />
      <Stack size="large">
        <Section withPadding size="max">
          <Stack size="small">
            <Heading1>Active Project Workplace colors</Heading1>
            <p>These are the colors we use in Cisco Project Workplace...</p>
            <Label>
              NB! There is no 1 to 1 mapping on where the colors is used, this only lists out all the individual colors
              out-of-order.
            </Label>
            <Label>Hover over a color to get the style value (hex/rgb/rgba)</Label>
          </Stack>
        </Section>

        <Section withPadding size="max">
          <Stack size="small">
            <Heading2>PWP branding. Active colors</Heading2>
            <BrandedColors variables={pwpVariables} />
          </Stack>
        </Section>

        <Section withPadding size="max">
          <Stack size="small">
            <Heading2>WWT branding. Active colors</Heading2>
            <BrandedColors variables={wwtVariables} />
          </Stack>
        </Section>

        <Section withPadding size="max">
          <Stack size="small">
            <Heading2>All defined colors</Heading2>
            <Palette>
              {allColors.map(([color], index) => (
                <PaletteColor
                  key={color}
                  title={`${color} - name: ${lookupColorName(color)}`}
                  background={color}
                  debug={!lookupColorName(color)}
                />
              ))}
            </Palette>
          </Stack>
        </Section>
        <Spacing />
      </Stack>
    </>
  );
}

function BrandedColors({ variables }: { variables: WhitelabelBranding }) {
  return (
    <Palette>
      {Object.entries(
        extractBrandedColors(variables)
          .map(colorEntry => Object.entries(colorEntry))
          .reduce((acc, [[name, value]]) => ({ ...acc, [value]: name }), {})
      ).map(([color]) => (
        <PaletteColor
          key={color}
          title={`${color} - name: ${lookupColorName(color)}`}
          background={color}
          debug={!lookupColorName(color)}
        />
      ))}
    </Palette>
  );
}

function extractBrandedColors(variables: typeof pwpVariables, debug?: boolean): Array<Record<string, string>> {
  let nestedColors = traverse(variables);
  let extractedColors = (nestedColors !== null && Array.isArray(nestedColors) ? nestedColors : [])
    .flat(3)
    .filter(colorEntry => Boolean(colorEntry)) as Array<Record<string, string>>;
  return extractedColors;
}

type VarValue = Record<string, string> | null | Array<VarValue>;

function traverse(varObject: object | number | string): VarValue {
  // to prefix key with parent keys to get their names, instead of key return { [`${keyPrefix ? `${keyPrefix}-` : ``}${key}`]: value } // add traverseEntry arg  (keyPrefix?: string)
  function traverseEntry([key, value]: [string, ...Parameters<typeof traverse>]): ReturnType<typeof traverse> {
    if (typeof value !== 'object' && typeof value !== 'string') return null;
    return typeof value === 'object'
      ? Object.entries(value).map(([_key, value]) => traverseEntry([_key, value]))
      : value.match(/^#|^rgb/) && { [key]: value };
  }
  return traverseEntry(['', varObject]);
}

function lookupColorName(queryValue: string) {
  let [, matchedName] = Object.entries(completeColorList).find(([value]) => queryValue === value) || [];
  return matchedName;
}

function flipKeyAndValues(keyValueObject: Record<string, string>) {
  return Object.entries(keyValueObject).reduce((acc, [name, value]) => ({ ...acc, [value]: name }), {});
}

let Label = styled.pre`
  font-family: courier;
  font-size: 12px;
  border: 1px solid #eeeeee;
  border-radius: 2px;
  padding: 0 ${universalMargin}px;
`;

let Spacing = styled.div`
  padding-top: ${universalMargin * 8}px;
`;

let Palette = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${universalMargin * 2}px;
`;

let PaletteColor = styled.div<{ background: string; debug: boolean }>`
  width: calc((min(100vw, 1440px) - 128px - 8px * 24) / 12);
  height: calc((min(100vw, 1440px) - 128px - 8px * 24) / 12);
  border: 1px solid ${legacyColors.grey30};
  ${props =>
    props.debug &&
    css`
      box-shadow: 0 0 0 3px tomato;
    `}
  background: ${props => props.background};
  font-size: 11px;
  line-height: 1.25em;
  overflow: hidden;
  border-radius: 3px;
`;

let Heading1 = styled.h1`
  ${h2Heading}
`;

let Heading2 = styled.h2`
  ${h4Heading}
`;
