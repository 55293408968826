import React from 'react';
import styled from 'styled-components';
import { universalMargin } from 'styles/variables';
import { mediaQueries } from 'styles/breakpoints';

/** TODO: Define these, and corresponding mappings together */

type Sizes = 'small' | 'medium' | 'large' | 'extralarge';

const mapSizes: Record<Sizes, number> = {
  small: universalMargin * 2,
  medium: universalMargin * 4,
  large: universalMargin * 8,
  extralarge: universalMargin * 16,
};

export const Stack = styled.div<{ size: Sizes }>`
  > *:not(:last-child) {
    margin-bottom: ${props => `${mapSizes[props.size] * 0.5}px`};
  }

  ${props =>
    mediaQueries.small(`
    > *:not(:last-child) {
      margin-bottom: ${mapSizes[props.size] * 0.75}px
    }
    `)}

  ${props =>
    mediaQueries.large(`
    > *:not(:last-child) {
      margin-bottom: ${mapSizes[props.size]}px
    }
    `)}
`;

export const UnStack = styled.div`
  margin-bottom: 0 !important;
`;

type ConditionalUnstackProps = {
  unstack?: boolean;
  children?: React.ReactNode | React.ReactNodeArray;
};

export function ConditionalUnstack(props: ConditionalUnstackProps) {
  if (props.unstack) return <UnStack>{props.children}</UnStack>;
  return <>{props.children}</>;
}
